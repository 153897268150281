import Link from "next/link";

import Typography from "@/components/Typography";
import Clock from "@/icons/clock.svg";
import useRequest from "@/helpers/useRequest";
import { apiPath } from "@/helpers/apiPath";
import { applicationRoutes } from "@/helpers/applicationRouting";
import { FeedTypes } from "@/helpers/pageHelpers/Common/interface";
import { formatDate } from "@/helpers/formatDate";
import { getReadingTime } from "@/helpers/formatDuration";
import { descSorting } from "@/helpers/apiPathHelpers";

import styles from "./LastBookmarked.module.scss";

const LastBookmarked = () => {
  const { data } = useRequest(
    apiPath.BOOKMARKS({ page: 1, sort: descSorting })
  );
  const maxElements = 4;
  const bookmarked = data?.items.filter(({ type }) => type !== FeedTypes.Ad);

  const getApplicationRoute = (type) => {
    switch (type) {
      case FeedTypes.Article:
        return applicationRoutes.ARTICLE;
      case FeedTypes.Episode:
        return applicationRoutes.PODCAST_EPISODE;
    }
  };

  if (!bookmarked || bookmarked?.length < 1) return <></>;

  return (
    <section className={styles.container}>
      <Typography component="h5" variant="h5" className={styles.header}>
        Ostatnio zapisane
      </Typography>
      <div>
        {bookmarked
          ?.slice(0, maxElements)
          .map(
            ({ object: { slug, title, publishedAt, readingTime }, type }) => {
              return (
                <div key={title} className={styles.element}>
                  <Link
                    href={`${getApplicationRoute(type)}[slug]`}
                    as={getApplicationRoute(type) + slug}
                  >
                    <a className={styles.link}>
                      <Typography
                        component="p"
                        variant="h6"
                        className={styles.title}
                      >
                        {title}
                      </Typography>
                    </a>
                  </Link>
                  <div className={styles.timeContainer}>
                    {publishedAt && (
                      <Typography variant="small" component="time">
                        {formatDate(publishedAt)}
                      </Typography>
                    )}

                    {readingTime && (
                      <>
                        <Typography variant="small" component="span">
                          •
                        </Typography>
                        <Clock />
                        <Typography variant="small" component="span">
                          {getReadingTime(readingTime)}
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
              );
            }
          )}
      </div>
      {bookmarked?.length > maxElements && (
        <Link href={applicationRoutes.BOOKMARKED}>
          <a className={styles.more}>
            <Typography component="span" variant="medium">
              Zobacz wszystkie
            </Typography>
          </a>
        </Link>
      )}
    </section>
  );
};

export default LastBookmarked;
