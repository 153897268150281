import Image from "next/image";
import Link from "next/link";

import Avatar from "@/components/Avatar";
import BookmarkButton from "@/components/BookmarkButton";
import Tag from "@/components/Tag";
import Typography from "@/components/Typography";
import { ContentSourceEnum } from "@/components/BookmarkButton/BookmarkButton.interface";
import { Schema } from "@/components/MetaHead/Head.interface";
import { applicationRoutes } from "@/helpers/applicationRouting";
import { getImgixURL } from "@/helpers/getImgixURL";
import { ArticleTileProps } from "@/helpers/pageHelpers/Article/interface";
import { PlacementTypes } from "@/helpers/pageHelpers/Common/interface";
import { useDataLayer } from "@/helpers/useDataLayer";
import Clock from "@/icons/clock.svg";
import { getReadingTime } from "@/helpers/formatDuration";

import styles from "./FeaturedTile.module.scss";

const FeaturedArticleTile: React.FC<ArticleTileProps> = ({
  id,
  title,
  slug,
  image,
  tags,
  author,
  bookmarked,
  sneakPeak,
  readingTime,
}) => {
  const { pushToDataLayer } = useDataLayer();
  const img = getImgixURL(image);
  const tagsToDisplay = 2;

  const pushData = () => {
    const objectToSend = {
      author: author.name,
      title: title,
      type: Schema.Page,
      tags: `${tags?.map((tag) => tag.title)}`,
      placement: PlacementTypes.Featured,
    };
    pushToDataLayer("link_click", objectToSend);
  };

  return (
    <div className={styles.tile} key={id}>
      <Link
        href={`${applicationRoutes.ARTICLE}[slug]`}
        as={applicationRoutes.ARTICLE + slug}
      >
        <a className={styles.image} onClick={pushData}>
          <Image
            layout="fill"
            objectFit="cover"
            src={`${img}?facepad=10&fit=facearea&faceindex=N`}
            alt={title}
            placeholder="blur"
            blurDataURL={`https://newonce.imgix.net/${img}?fm=blurhash`}
          />
        </a>
      </Link>
      <div className={styles.meta}>
        <Link
          href={`${applicationRoutes.ARTICLE}[slug]`}
          as={applicationRoutes.ARTICLE + slug}
        >
          <a className={styles.title} onClick={pushData}>
            <Typography variant="h3" component="h3">
              {title}
            </Typography>
          </a>
        </Link>
        <Typography variant="medium" component="p" className={styles.sneakPeak}>
          {sneakPeak}
        </Typography>
        {readingTime && (
          <div className={styles.time}>
            <Clock />
            <Typography variant="medium" component="p">
              {getReadingTime(readingTime)}
            </Typography>
          </div>
        )}
      </div>
      <div className={styles.bottom}>
        <Link
          key={author?.id}
          href={{
            pathname: `${applicationRoutes.AUTHOR}[slug]`,
            query: { from: "Feed" },
          }}
          as={applicationRoutes.AUTHOR + author?.slug}
        >
          <a className={styles.author}>
            <Avatar
              key={author.id}
              src={author?.avatar?.url}
              alt={author.name}
              wrapperClassName={styles.avatar}
            />
            <Typography variant="medium" component="p">
              {author?.name}
            </Typography>
          </a>
        </Link>

        {!!tags?.length &&
          tags.slice(0, tagsToDisplay).map(({ slug, title }) => (
            <Link
              key={title}
              href={`${applicationRoutes.TAG}[slug]`}
              as={applicationRoutes.TAG + slug}
            >
              <a className={styles.tag}>
                <Tag title={title}>{title}</Tag>
              </a>
            </Link>
          ))}
      </div>
      <BookmarkButton
        variant="round"
        size="medium"
        slug={slug}
        source={ContentSourceEnum.Articles}
        bookmarked={bookmarked}
        className={styles.bookmarkButton}
      />
    </div>
  );
};

export default FeaturedArticleTile;
