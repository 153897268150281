import fs from "fs";

import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useStoreState } from "pullstate";
import localforage from "localforage";
import dynamic from "next/dynamic";
import classnames from "classnames";

import { apiPath } from "@/helpers/apiPath";
import { HomeInterface } from "@/helpers/pageHelpers/Common/interface";
import { UIStore } from "@/store/UIStore";
import styles from "@/containers/Feed/Feed.module.scss";
import Homepage from "@/containers/Homepage";
import Feeds from "@/containers/Feed";
import PageWrapper from "@/components/PageWrapper";
import { FeedType } from "@/helpers/pageHelpers/Store/interface";
import client, { apiClientV2 } from "@/helpers/apiClient";
import MetaHead from "@/components/MetaHead";
import { Schema } from "@/components/MetaHead/Head.interface";
import { getRSS } from "@/helpers/getRSSFeed";
import FeaturedSection from "@/components/FeaturedSection";
import Recommended from "@/components/Recommended";
import { FeedQuery } from "@/containers/Feed/Feed.interface";
import ContinueReadingSection from "@/components/ContinueReadingSection";

const PersonalFeed = dynamic(import("@/containers/Feed/PersonalFeed"), {
  ssr: false,
});
const PersonalFeeds = dynamic(import("@/containers/Feed/PersonalFeeds"), {
  ssr: false,
});
const YouMayAlsoLike = dynamic(import("@/components/YouMayAlsoLike"), {
  ssr: false,
});

const Home: React.FC<HomeInterface> = ({ items, cover, seo }) => {
  const [savedFeed, setSavedFeed] = useState<FeedType>();
  const router = useRouter();
  const { userLoggedIn, feedType } = useStoreState(UIStore, (store) => store);
  const feedQuery = router?.query?.feed;
  const personalFeedQuery = router?.query?.moje;
  const hasPersonalFeedQuery =
    !!router.query.moje &&
    (personalFeedQuery === FeedQuery.Article ||
      personalFeedQuery === FeedQuery.Podcast);
  const showPersonalFeed =
    userLoggedIn &&
    feedType === FeedType.Personal &&
    savedFeed !== FeedType.Main;
  const schemaData = {
    type: Schema.Page,
    title: "Strona główna",
    slug: "",
  };

  const metaData = {
    description: seo.metaDescription,
    ...seo,
  };

  const getSavedFeedType = async () => {
    try {
      return await localforage.getItem("feedType");
    } catch (err) {}
  };

  useEffect(() => {
    getSavedFeedType().then((val: FeedType) => {
      UIStore.update((store) => {
        store.feedType = val;
      });
      setSavedFeed(val);
    });
  }, [feedType]);

  if (feedQuery) {
    return <Feeds />;
  }
  if (userLoggedIn && hasPersonalFeedQuery) {
    return <PersonalFeeds />;
  }

  return (
    <>
      <MetaHead schemaData={schemaData} metaData={metaData} />

      <PageWrapper>
        <div
          className={classnames(
            styles.feedContainer,
            showPersonalFeed && styles.personalFeed
          )}
        >
          {showPersonalFeed ? (
            <PersonalFeed />
          ) : (
            <>
              <FeaturedSection initialData={cover} />
              <ContinueReadingSection />
              <Homepage items={items} />
            </>
          )}
        </div>
        <Recommended />
        {userLoggedIn && <YouMayAlsoLike />}
      </PageWrapper>
    </>
  );
};

export async function getStaticProps() {
  const clientV2 = apiClientV2();
  const { data: seo } = await client.get(apiPath.SEO);
  const { data } = await clientV2.get(apiPath.FEED({ page: 1 }));
  const { data: rssData } = await clientV2.get(
    apiPath.FEED({ page: 1, perPage: 55 })
  );
  const { data: coverableContents } = await client.get(
    apiPath.COVERABLE_CONTENTS
  );

  const rss = getRSS(rssData.items);

  fs.writeFile("./public/rss.xml", rss, () => null);

  return {
    props: {
      ...data,
      seo,
      cover: coverableContents,
    },
    revalidate: 300,
  };
}

export default Home;
