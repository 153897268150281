import React, { Fragment, useState } from "react";
import dynamic from "next/dynamic";
import classNames from "classnames";

import { apiPath } from "shared/helpers/apiPath";
import Container from "@/components/Container";
import { defaultPageItems } from "@/helpers/apiPathHelpers";
import styles from "@/containers/Feed/Feed.module.scss";
import { GridColumns } from "@/components/Container/Container.interface";
import Button from "@/components/Button";
import { renderFeedElement } from "@/containers/Feed/renderFeedElement";
import SkeletonLoader from "@/components/SkeletonLoader";
import SectionHeading from "@/components/SectionHeading";
import {
  FeedInterface,
  FeedTypes,
} from "@/helpers/pageHelpers/Common/interface";
import { usePagination } from "@/helpers/usePagination";
import { feedFilters } from "@/helpers/feedsCategories";
import { FeedCategory } from "@/containers/Feed/Feed.interface";
import LastBookmarked from "@/components/LastBookmarked";

const PopularArticles = dynamic(import("@/components/PopularArticles"), {
  ssr: false,
});

const HomepageMobileTopAd = dynamic(
  import("@/components/Advertisement/HomeMobileTop"),
  {
    ssr: false,
  }
);

const HomepageTopAd = dynamic(import("@/components/Advertisement/HomeTop"), {
  ssr: false,
});

const SidebarAd = dynamic(import("@/components/Advertisement/Sidebar"), {
  ssr: false,
});

const Homepage: React.FC<FeedInterface> = ({ items }) => {
  const [activeCategory, setActiveCategory] = useState<FeedCategory>(
    FeedCategory.All
  );

  const {
    elements: feedElements,
    isLoadingMore,
    isReachingEnd,
    isLoadingInitialData,
    loadMore,
  } = usePagination(
    activeCategory === FeedCategory.All && items,
    defaultPageItems.MAIN_FEED,
    (index) => apiPath.FEED({ page: index + 1, contentType: activeCategory }),
    2
  );

  let advertisementIndex = 0;

  return (
    <>
      <Container>
        <HomepageTopAd />
      </Container>

      <Container grid={GridColumns.Twelve}>
        <div className={styles.feedTiles}>
          <SectionHeading
            title="Najnowsze"
            className={styles.feedHeading}
          />
          <div className={styles.feedFilters}>
            {feedFilters.map(({ title, type }) => (
              <button
                key={title}
                className={classNames(
                  styles.button,
                  activeCategory === type && styles.active,
                  activeCategory === type && `active_filter_${type}`
                )}
                onClick={() => setActiveCategory(type)}
              >
                {title}
              </button>
            ))}
          </div>

          {feedElements.map((element, index) => {
            if (element.type === FeedTypes.Ad) {
              advertisementIndex++;
            }

            if (index === 0) {
              return (
                <Fragment key={index}>
                  {renderFeedElement(
                    element.type,
                    element.object,
                    advertisementIndex,
                    index
                  )}

                  <HomepageMobileTopAd />
                </Fragment>
              );
            }

            return (
              <Fragment key={index}>
                {renderFeedElement(
                  element.type,
                  element.object,
                  advertisementIndex,
                  index
                )}
              </Fragment>
            );
          })}

          {isLoadingMore && <SkeletonLoader />}

          {!isReachingEnd && !isLoadingInitialData && (
            <Button
              variant="secondary"
              onClick={loadMore}
              className={classNames(styles.loadMore, "feed_loadMore")}
            >
              {isLoadingMore ? "Wczytuję..." : "Wczytaj więcej"}
            </Button>
          )}
        </div>

        <div className={styles.popularArticles}>
          <PopularArticles className={"show-on-xl"} />
          <LastBookmarked />
          <SidebarAd />
        </div>
      </Container>
    </>
  );
};

export default Homepage;
