import PageWrapper from "@/components/PageWrapper";
import Tag from "@/containers/Feed/Tag";

const Feeds: React.FC = () => {
  const pageData = { headerLabel: "Feed" };
  return (
    <PageWrapper pageData={pageData}>
      <Tag />
    </PageWrapper>
  );
};

export default Feeds;
