import { useStoreState } from "pullstate";

import Container from "@/components/Container";
import PodcastTile from "@/components/FeedTiles/PodcastTile";
import Typography from "@/components/Typography";
import { ContextualMenuEnum } from "@/components/ContextualMenu/ContextualMenu.interface";
import { PodcastTileInterface } from "@/helpers/pageHelpers/Podcast/interface";
import { useScroll } from "@/helpers/useScroll";
import { ArticleTileProps } from "@/helpers/pageHelpers/Article/interface";
import useRequest from "@/helpers/useRequest";
import { apiPath } from "@/helpers/apiPath";
import { PlacementTypes } from "@/helpers/pageHelpers/Common/interface";
import { UIStore } from "@/store/UIStore";

import styles from "./ContinueReadingSection.module.scss";
import { ContinueReadingContentTypes } from "./ContinueReadingSection.interface";
import ContinueReadingArticleTile from "./components/ContinueReadingArticleTile";

const ContinueReadingSection = () => {
  const { userLoggedIn } = useStoreState(UIStore, (store) => store);
  const { scrollWrapperRef } = useScroll();
  const { data } = useRequest(apiPath.RESOURCE_STATUSES({ page: 1 }));
  const isContentAvailable = userLoggedIn && data?.length > 0;

  if (!isContentAvailable) return <></>;

  return (
    <section className={styles.wrapper}>
      <Container>
        <Typography variant="h3" component="h3">
          Kontynuuj już rozpoczęte
        </Typography>
      </Container>
      <div className={styles.started} ref={scrollWrapperRef}>
        {data.map(({ type, object }, index) => {
          switch (type) {
            case ContinueReadingContentTypes.ARTICLE:
              return (
                <ContinueReadingArticleTile
                  key={index}
                  data={object as ArticleTileProps}
                />
              );
            case ContinueReadingContentTypes.EPISODE:
              return (
                <PodcastTile
                  key={index}
                  {...(object as PodcastTileInterface)}
                  size="small"
                  contextualMenuVariant={ContextualMenuEnum.Latest}
                  className={styles.podcastTile}
                  placement={PlacementTypes.Unfinished}
                />
              );
            default:
              <></>;
          }
        })}
      </div>
    </section>
  );
};

export default ContinueReadingSection;
