import Image from "next/image";

import BookmarkButton from "@/components/BookmarkButton";
import { ContentSourceEnum } from "@/components/BookmarkButton/BookmarkButton.interface";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import { getImgixURL } from "@/helpers/getImgixURL";

import styles from "../ContinueReadingSection.module.scss";
import { ContinueReadingArticlesInterface } from "../ContinueReadingSection.interface";

const ContinueReadingArticleTile: React.FC<
  ContinueReadingArticlesInterface
> = ({ data: { title, slug, bookmarked, image } }) => {
  const img = getImgixURL(image);
  return (
    <div className={styles.articleTile}>
      <Image
        src={img}
        objectFit="cover"
        layout="fill"
        alt={title}
        className={styles.backgroundImage}
      />
      <Typography variant="h5" component="h5" className={styles.articleTitle}>
        {title}
      </Typography>
      <div className={styles.action}>
        <Button variant="secondaryStroke" className={styles.button}>
          Czytaj dalej
        </Button>
        <BookmarkButton
          variant="round"
          slug={slug}
          source={ContentSourceEnum.Articles}
          bookmarked={bookmarked}
          size="small"
        />
      </div>
    </div>
  );
};

export default ContinueReadingArticleTile;
