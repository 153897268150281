import React from "react";
import { useRouter } from "next/router";

import MetaHead from "@/components/MetaHead";
import TagList from "@/containers/Feed/Tag/TagList";
import { tagsMetaData } from "@/helpers/pageMetaData";
import { tagList } from "@/helpers/feedsCategories";
import Custom404 from "src/pages/404";

import styles from "../Feed.module.scss";

const Tag: React.FC = () => {
  const router = useRouter();
  const query = router.query?.feed;
  const tag = tagList.find(
    ({ slug, title }) => slug === query && title
  )?.title;

  const tagMeta = tagsMetaData(tag);

  if (!tag) {
    return <Custom404 />;
  }

  return (
    <>
      <MetaHead metaData={tagMeta} />
      <div className={styles.feedContainer}>
        <TagList query={query} isFeed />
      </div>
    </>
  );
};
export default Tag;
