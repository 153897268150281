import { useRef, useState } from "react";
import classNames from "classnames";

import { apiPath } from "@/helpers/apiPath";
import useRequest from "@/helpers/useRequest";
import Container from "@/components/Container";
import FeaturedTiles from "@/components/FeedTiles/FeaturedTiles";

import { FeaturedSectionInterface } from "./FeaturedSection.interface";
import styles from "./FeaturedSection.module.scss";

export const FeaturedSection: React.FC<FeaturedSectionInterface> = ({
  initialData,
}) => {
  const { data } = useRequest(apiPath.COVERABLE_CONTENTS, {
    initialData,
  });

  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const wrapperRef = useRef(null);
  const stepProperties = wrapperRef?.current?.getBoundingClientRect();
  const stepWidth = stepProperties?.width + 32;
  const itemsToDisplay = 3;

  const handleSlideChange = (index: number) => {
    const scrollToElement = stepWidth * index;
    wrapperRef.current.scrollTo({
      left: scrollToElement,
    });
    setActiveStepIndex(index);
  };

  const onScrollSlideChange = () => {
    const index = Math.round(wrapperRef?.current?.scrollLeft / stepWidth);
    activeStepIndex !== index && setActiveStepIndex(index);
  };

  return (
    <>
      {data && (
        <Container>
          <div className={styles.steps}>
            {Array.from(Array(itemsToDisplay), (_, index) => (
              <button
                key={index}
                className={classNames(
                  styles.step,
                  activeStepIndex === index && styles.active
                )}
                onClick={() => handleSlideChange(index)}
              />
            ))}
          </div>
          <div
            className={styles.container}
            ref={wrapperRef}
            onScroll={onScrollSlideChange}
          >
            <FeaturedTiles data={data} itemsToDisplay={itemsToDisplay} />
          </div>
        </Container>
      )}
    </>
  );
};

export default FeaturedSection;
