import { ArticleTileProps } from "shared/helpers/pageHelpers/Article/interface";
import { PodcastTileInterface } from "@/helpers/pageHelpers/Podcast/interface";

export enum FeaturedContentTypes {
  ARTICLE = "ARTICLE",
  EPISODE = "EPISODE",
}

export interface FeaturedContentData {
  type: FeaturedContentTypes;
  object: ArticleTileProps | PodcastTileInterface;
}

export interface FeaturedSectionInterface {
  initialData?: FeaturedContentData;
}
