import { FeaturedContentTypes } from "@/components/FeaturedSection/FeaturedSection.interface";
import FeaturedArticleTile from "@/components/FeedTiles/FeaturedTiles/FeaturedArticleTile";
import FeaturedEpisodeTile from "@/components/FeedTiles/FeaturedTiles/FeaturedEpisodeTile";

const FeaturedTiles = ({ data, itemsToDisplay }) =>
  data.slice(0, itemsToDisplay).map(({ type, object }, index) => {
    switch (type) {
      case FeaturedContentTypes.ARTICLE:
        return <FeaturedArticleTile key={index} {...object} />;
      case FeaturedContentTypes.EPISODE:
        return <FeaturedEpisodeTile key={index} {...object} />;
      default:
        return <></>;
    }
  });

export default FeaturedTiles;
