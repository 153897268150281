import { ArticleTileProps } from "@/helpers/pageHelpers/Article/interface";
import { PodcastTileInterface } from "@/helpers/pageHelpers/Podcast/interface";

export enum ContinueReadingContentTypes {
  ARTICLE = "Article",
  EPISODE = "Episode",
}

export interface ContinueReadingContentInterface {
  type: ContinueReadingContentTypes;
  object: ArticleTileProps | PodcastTileInterface;
}

export interface ContinueReadingArticlesInterface {
  data: ArticleTileProps;
}
