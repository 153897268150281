import React from "react";
import Image from "next/image";
import classnames from "classnames";

import { getImgixURL } from "@/helpers/getImgixURL";

import { AvatarProps } from "./Avatar.interface";
import styles from "./Avatar.module.scss";

const portal = process.env.NEXT_PUBLIC_PORTAL;

export const Avatar: React.FC<AvatarProps> = ({
  src,
  isMusicTile,
  alt,
  size,
  className,
  wrapperClassName,
}) => {
  if (isMusicTile) {
    return (
      <div
        className={classnames(
          styles.avatarBase,
          styles.avatar,
          className && className,
          styles[size]
        )}
      >
        <Image
          layout="fill"
          src={getImgixURL(src)}
          alt={alt}
          objectFit="cover"
        />
      </div>
    );
  }

  return (
    <div
      className={classnames(
        styles.wrapper,
        portal === "sport" && styles.sport,
        wrapperClassName && wrapperClassName,
        styles.avatar,
        className && className,
        styles[size]
      )}
      style={{ backgroundImage: `url(${src})` }}
    >
      <Image
        layout="fill"
        src={getImgixURL(`${src}`)}
        alt={alt}
        objectFit="cover"
      />
    </div>
  );
};

export default Avatar;
