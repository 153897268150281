import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { parseCookies } from "nookies";
import { useStoreState } from "pullstate";

import ContextualMenu from "@/components/ContextualMenu";
import EpisodePlayer from "@/components/Players/EpisodePlayer";
import Typography from "@/components/Typography";
import { ContentSourceEnum } from "@/components/BookmarkButton/BookmarkButton.interface";
import { ContextualMenuEnum } from "@/components/ContextualMenu/ContextualMenu.interface";
import { getImgixURL } from "@/helpers/getImgixURL";
import { applicationRoutes } from "@/helpers/applicationRouting";
import { PodcastTileInterface } from "@/helpers/pageHelpers/Podcast/interface";
import { PlacementTypes } from "@/helpers/pageHelpers/Common/interface";
import { PlayerStore } from "@/store/PlayerStore";

import styles from "./FeaturedTile.module.scss";

const FeaturedEpisodeTile: React.FC<PodcastTileInterface> = ({
  bookmarked,
  id,
  images,
  hosts,
  media: { duration, longVersionDuration, url },
  picture,
  podcast,
  publishedAt,
  slug,
  tags,
  time,
  title,
}) => {
  const { listenedEpisodes } = useStoreState(PlayerStore, (store) => store);
  const img = getImgixURL(picture);
  const artwork = images.feed.lgx2;
  const mediaMetaData = {
    title: title,
    artwork: artwork,
    subTitle: podcast.title,
    slug: slug,
    podcast: { slug: podcast.slug },
    tags: !!tags?.length && tags.map(({ title }) => title),
    authors: !!hosts?.length && hosts.map(({ name }) => name),
    publishedAt,
    placement: PlacementTypes.Featured,
  };

  const cookies = parseCookies();
  const apiToken = cookies?.token;
  const audioURL = apiToken ? `${url}?api_token=${apiToken}` : url;
  const episodeTime = listenedEpisodes?.[slug]?.status || time;

  return (
    <div className={styles.tile} key={id}>
      <Link
        href={`${applicationRoutes.PODCAST_EPISODE}[slug]`}
        as={applicationRoutes.PODCAST_EPISODE + slug}
      >
        <a className={classNames(styles.image, styles.episodeImage)}>
          <Image
            layout="fill"
            objectFit="cover"
            src={img}
            alt={title}
            placeholder="blur"
            blurDataURL={`https://newonce.imgix.net/${img}?fm=blurhash`}
          />
        </a>
      </Link>
      <div className={styles.episodeMeta}>
        <Link
          href={`${applicationRoutes.PODCAST}[slug]`}
          as={applicationRoutes.PODCAST + podcast.slug}
        >
          <a className={styles.podcastTitle}>
            <Typography variant="medium" component="p">
              {podcast.title}
            </Typography>
          </a>
        </Link>
        <Link
          href={`${applicationRoutes.PODCAST_EPISODE}[slug]`}
          as={applicationRoutes.PODCAST_EPISODE + slug}
        >
          <a className={styles.title}>
            <Typography variant="h3" component="h3">
              {title}
            </Typography>
          </a>
        </Link>
        <ContextualMenu
          source={ContentSourceEnum.Episodes}
          slug={slug}
          podcast={{ slug: podcast.slug }}
          title={podcast.title}
          subtitle={title}
          variant={ContextualMenuEnum.Latest}
          duration={duration}
          episodeTime={time}
          className={styles.menu}
          artwork={artwork}
        />
      </div>
      <div className={styles.episodePlayer}>
        <EpisodePlayer
          url={audioURL}
          duration={duration}
          longVersionDuration={longVersionDuration}
          publishedAt={publishedAt}
          bookmarked={bookmarked}
          metaData={mediaMetaData}
          matureContent={podcast.matureContent}
          time={episodeTime}
          size="small"
          bookmarkButtonSize="medium"
        />
      </div>
    </div>
  );
};

export default FeaturedEpisodeTile;
